import axios from 'axios'
import {notify} from "../components/Notify";
import {nodeFromString} from "../tools/NodeFromString";

export default function orders() {
    let container = document.querySelector('.order-products-container')

    if (container) {
        let list = container.querySelector('.order-products'),
            count = container.querySelectorAll('.order-product').length

        function initItem(item) {
            item.querySelector('.order-product-remove')?.addEventListener('click', e => {
                e.preventDefault()
                item.remove()
                resolveOrderPrice()
            })
            item.querySelector('.select-product-count')?.addEventListener('change', e => {
                if (e.target.value.length === 0) {
                    e.target.value = 0
                }
                resolveOrderPrice()
            })
            item.querySelector('.select-product')?.addEventListener('change', e => {
                e.preventDefault()
                item.querySelector('.select-product-price').value = e.target.selectedOptions[0].dataset.price
                resolveOrderPrice()
            })
        }
        /** Перерасчет итоговой стоимости букета */
        function resolveOrderPrice() {
            let products = container.querySelectorAll('.order-product')
            let orderPrice = 0
            Array.from(products).forEach(item => {
                let price = item.querySelector('.select-product-price').value
                let count = item.querySelector('.select-product-count').value
                item.querySelector('.select-product-result').innerHTML = (parseFloat(price) * parseInt(count)).toString()
                orderPrice += parseFloat(price) * parseInt(count);
            })
            let orderPriceElement = document.getElementById("order-price")
            orderPriceElement.innerHTML = orderPrice.toString()
        }
        function initAdd() {
            container.querySelector('.order-products-add')?.addEventListener('click', e => {
                e.preventDefault()
                axios.get(`/admin/orders/products?index=${++count}`)
                    .then(result => {
                        let item = nodeFromString(result.data.data)
                        initItem(item)
                        list.append(item)
                    })
                    .catch(error => {
                        notify('Error', 'danger', error)
                    })
            })
        }

        initAdd()
        container.querySelectorAll('.order-product').forEach(initItem)
    }
}

