import './components/Preloader'
import './components/Notify'
import './components/Tabs'
import './components/ImageCropper'
import './components/ImageSaver'

import datetimePicker from "./components/DatetimePicker";
import datePicker from "./components/DatePicker";
import SingleImageCropper from './components/SingleImageCropper'
import SingleImageSaver from "./components/SingleImageSaver";
import Gallery from "./components/Gallery";
import Editor from "./components/Editor";
import Dropzone from "./components/Dropzone";
import phoneMask from "./components/PhoneMask";

import orders from "./pages/orders";

document.querySelectorAll('[single-image-saver]').forEach(input => {
    new SingleImageSaver(input)
})
document.querySelectorAll('[single-image-cropper]').forEach(input => {
    new SingleImageCropper(input)
})
document.querySelectorAll('[gallery]').forEach(input => {
    new Gallery(input)
})
document.querySelectorAll('[editor]').forEach(input => {
    new Editor(input)
})
document.querySelectorAll('[dropzone]').forEach(input => {
    new Dropzone(input)
})
document.querySelectorAll('[phone-mask]').forEach(input => {
    phoneMask(input)
})
document.querySelectorAll('[datetime-picker]').forEach(input => {
    datetimePicker(input)
})
document.querySelectorAll('[date-picker]').forEach(input => {
    datePicker(input)
})

document.addEventListener('DOMContentLoaded', () => {
    orders()
})